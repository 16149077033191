import { stripTrailingSlash } from '../helpers/text/stripTrailingSlash';

/**
 * Environment variables come through as strings.
 */
export const convertStrToBool = (str: string) => str === 'true';
export const convertStrToNumber = (str: string, fallback: number) => {
    const number = Number(str);
    return Number.isNaN(number) ? fallback : number;
}

/**
 * Are we running dev or build environment.
 */
export const NODE_ENV = process.env.NODE_ENV || 'development';

/**
 * Is site maintenance mode enabled.
 */
export const MAINTENANCE_MODE_ENABLED = convertStrToBool(process.env.MAINTENANCE_MODE_ENABLED || '');

/**
 * ------------------------------------------
 *
 * Mock Service Worker environment variables
 *
 * ------------------------------------------
 */

/**
 * Turns mocks on for us.
 */
// const USE_MOCKS = convertStrToBool(process.env.GATSBY_USE_MOCKS) || false;

/**
 * Force all urls to be msw.
 */
// const FORCE_ALL_MSW = convertStrToBool(process.env.GATSBY_FORCE_ALL_MSW) || false;

// /** The url we will use for mocking requests */
export const MSW_URL = process.env.NEXT_PUBLIC_MSW_URL || '';

/**
 * We have a lot of mock environment variables so here are some helpers to help what's going on.
 */
// const areMocksEnabled = NODE_ENV === 'test' || NODE_ENV === 'testing' || FORCE_ALL_MSW === true;
// const areMocksForced = !!FORCE_ALL_MSW;

/**
 * ------------------------------------------
 *
 * Tracking config
 *
 * ------------------------------------------
 */

/**
 * Is tracking enabled globally.
 */
export const TRACKING_ENABLED = convertStrToBool(process.env.NEXT_PUBLIC_ALL_TRACKING_ENABLED || '');
export const isTrackingEnabled = () => TRACKING_ENABLED;

/**
 * Do we wan't to log tracking in console?
 */
export const LOG_TRACKING = convertStrToBool(process.env.NEXT_PUBLIC_LOG_TRACKING || '');

/**
 * Google analytics
 */
export const GOOGLE_ANALYTICS_ENABLED = convertStrToBool(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ENABLED || '');
export const isGoogleAnalyticsEnabled = () => GOOGLE_ANALYTICS_ENABLED;
export const GOOGLE_GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_GTM_ID || '';
export const ADVANCED_ECOM_ENABLED = convertStrToBool(process.env.NEXT_PUBLIC_ADVANCED_ECOM_ENABLED || '');

/**
 * Awin analytics documentation.
 * @see https://wiki.awin.com/index.php/Awin_Access_Technical_Integration_Developer_Guide_(sale)
 */
export const AWIN_ENABLED = convertStrToBool(process.env.NEXT_PUBLIC_AWIN_ENABLED || '');
export const isAwinEnabled = () => AWIN_ENABLED;
export const AWIN_ACCOUNT_ID = process.env.NEXT_PUBLIC_AWIN_ACCOUNT_ID || '';
export const AWIN_TEST_MODE = (() => {
    // Convert the test mode enabled to a number.
    const testModeEnabled = parseInt(process.env.NEXT_PUBLIC_AWIN_TEST_MODE || '', 10);

    // If the value is not a number or is not 1 or 0, return 1.
    if (Number.isNaN(testModeEnabled) || ![0, 1].includes(testModeEnabled)) {
        return 1;
    }

    // Returns 1 or 0;
    return testModeEnabled;
})();

/**
 * Bloomreach analytics
 */
export const isBloomreachEnabled = () => convertStrToBool(process.env.NEXT_PUBLIC_BLOOMREACH_ENABLED || '');
export const BLOOMREACH_PIXEL_ACCOUNT_ID = process.env.NEXT_PUBLIC_BLOOMREACH_PIXEL_ACCOUNT_ID || '';
export const BLOOMREACH_PIXEL_DOMAIN_KEY = process.env.NEXT_PUBLIC_BLOOMREACH_PIXEL_DOMAIN_KEY || '';

/**
 * VWO analytics
 */
export const VWO_ENABLED = convertStrToBool(process.env.NEXT_PUBLIC_VWO_ENABLED || '');
export const VWO_ACCOUNT_ID = process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID || '';

/**
 * Hotjar analytics
 */
export const HOTJAR_ENABLED = convertStrToBool(process.env.NEXT_PUBLIC_HOTJAR_ENABLED || '');
export const HOTJAR_ACCOUNT_ID = process.env.NEXT_PUBLIC_HOTJAR_ACCOUNT_ID || '';

/**
 * ------------------------------------------
 *
 * Sentry config
 *
 * ------------------------------------------
 */
export const SENTRY_ENABLED = convertStrToBool(process.env.NEXT_PUBLIC_SENTRY_ENABLED || '');
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || '';
export const SENTRY_PROJECT = process.env.NEXT_PUBLIC_SENTRY_PROJECT || 'gatsby-staging';
export const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development';
export const SENTRY_SAMPLE_RATE = convertStrToNumber(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || '', 1);

/**
 * ------------------------------------------
 *
 * Payments
 *
 * ------------------------------------------
 */

export const CHECKOUT_PUBLIC_KEY = process.env.NEXT_PUBLIC_CHECKOUT_PUBLIC_KEY as string;

/**
 * Comma separated string, Available options are:
 * - applepay
 * - googlepay
 */
export const ENABLED_PAYMENT_METHODS = process.env.NEXT_PUBLIC_ENABLED_PAYMENT_METHODS || '';

/**
 * Apple Pay
 */
export const APPLE_PAY_MERCHANT_ID = process.env.NEXT_PUBLIC_APPLE_PAY_MERCHANT_ID || '';
export const APPLE_PAY_MERCHANT_NAME = process.env.NEXT_PUBLIC_APPLE_PAY_MERCHANT_NAME || '';

/**
 * Google Pay
 */
export const GOOGLE_PAY_GATEWAY = process.env.NEXT_PUBLIC_GOOGLE_PAY_GATEWAY || '';
export const GOOGLE_PAY_MERCHANT_NAME = process.env.NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_NAME || '';
export const GOOGLE_PAY_MERCHANT_ID = process.env.NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_ID || '';
export const GOOGLE_PAY_SANDBOX_MODE = process.env.NEXT_PUBLIC_GOOGLE_PAY_SANDBOX_MODE
    ? convertStrToBool(process.env.NEXT_PUBLIC_GOOGLE_PAY_SANDBOX_MODE)
    : true;

/**
 * ------------------------------------------
 *
 * Other
 *
 * ------------------------------------------
 */

export const SITE_URL = stripTrailingSlash(process.env.NEXT_PUBLIC_SITE_URL || '');

/**
 * Default axios request api url.
 * if we are in testing environment this will force all urls to be msw enabled.
 * if we use FORCE_ALL_MSW this will force all urls to be msw enabled.
 */
export const API_URL = process.env.NEXT_PUBLIC_API_URL;

/**
 * Does what it says on the tin.
 */
export const isBrowser = () => typeof window !== 'undefined';

/**
 * Notice shows up in the checkout.
 */
export const DELIVERY_NOTICE = convertStrToBool(process.env.NEXT_PUBLIC_DELIVERY_NOTICE || '');

/**
 * Check if Web Workers are available.
 */
export const isWebWorkerDefined = typeof Worker !== 'undefined';

/**
 * Veriff integration. This ENV variable is always required.
 * @see https://developers.veriff.com/#introduction
 */
export const VERIFF_API_KEY = process.env.NEXT_PUBLIC_VERIFF_API_KEY as string;

export const REVIEWS_IO_STORE = process.env.NEXT_PUBLIC_REVIEWS_IO_STORE || '';

/**
 * The environment that the current code is being deployed to: development, staging or live.
 */
export const getDeploymentEnvironment = () => process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT || 'development';
