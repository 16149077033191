import { SITE_URL } from "../../config/config";

export const allowUrls = [
    /https?:\/\/((api|www)\.)?theindependentpharmacy\.co\.uk/,
    /https?:\/\/((staging.www)\.)?theindependentpharmacy\.co\.uk/,
    /https?:\/\/((nextjs)\.)?theindependentpharmacy\.co\.uk/,
    /https?:\/\/((nextjs-staging)\.)?theindependentpharmacy\.co\.uk/,
    SITE_URL,
    'https://www.googletagmanager.com/',
    'https://cdn.checkout.com/',
];

export const denyUrls = [
    /https?:\/\/((staging.www)\.)?theindependentpharmacy\.co\.uk\/studio/
];
